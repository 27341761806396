import { formatMoney } from '@/lib/util'

export const getters = {
  subtotal (state) {
    return formatMoney(state.app.cart.total_price)
  },
  giftMessage (state) {
    return state.app.cart.note
  },
  giftMessageEdited (state) {
    return state.giftMessageEdited
  }
}
